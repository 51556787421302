<template>
	<div class="col-12 col-md-4 col-md-offset-4 div-center">
		<div class="card card-md mx-2">
			<div class="card-body">
				<h2 class="card-title text-center mb-4">Sign in using SKAARHOJ SSO</h2>
				<div class="form-footer">
					<button type="submit" class="btn btn-primary w-100" v-on:click="signIn()">Signin</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const BASE_URL = process.env.VUE_APP_BASE_URL
const AUTH_URL = process.env.VUE_APP_AUTH_URL

export default {
	name: 'Signin',
	methods: {
		signIn () {
			window.location.replace(`${AUTH_URL}/origin?origin=${BASE_URL}`)
		}
	}
}
</script>
<style scoped>
.div-center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
</style>
