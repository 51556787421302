<template>
	<tr :class="{ 'table-warning': isLegacy }">
		<td>{{ deviceState.devicetype }}</td>
		<td>{{ deviceState.serial }}</td>
		<td v-if="!isLegacy">{{ deviceState.chipid }}</td>
		<td v-else><a target="_blank" :href="`https://cores.skaarhoj.com/?CID=${deviceState.chipid}`">{{ deviceState.chipid }}</a></td>
		<td><input class="form-control" @keyup.enter.stop="$event.target.blur()" :disabled="isLegacy"
				v-model="deviceState.deviceoptions" /></td>
		<td><input class="form-control" @keyup.enter.stop="$event.target.blur()" :disabled="isLegacy"
				v-model="deviceState.reference" /></td>
		<td><input class="form-control" @keyup.enter.stop="$event.target.blur()" :disabled="isLegacy"
				v-model="deviceState.comments" /></td>
		<td>
			<select class="form-control" v-model="deviceState.licensemode">
				<option></option>
				<option>explicit</option>
				<option>explicitcores</option>
			</select>
		</td>
		<td> <button class="btn btn-primary" @click="showLicenses">Licenses</button></td>
		<td v-if="isLegacy">{{ formatDate(deviceState.created) }}</td>
		<td v-else>{{ formatDate(deviceState.created_at) }}</td>
		<td>{{ formatDate(deviceState.last_seen) }}</td>
		<td>
			<button class="btn" :disabled="isLegacy" @click="resetBurnin">
				<font-awesome-icon icon="fire" />
			</button>
			<button class="btn btn-danger" :disabled="isLegacy" @click="deleteDevice">
				<font-awesome-icon icon="trash" />
			</button>
			<button class="btn btn-warning" v-if="isChanges" :disabled="isLegacy" @click="updateDevice">
				<font-awesome-icon icon="save" />
			</button>
		</td>
	</tr>
</template>

<script>
import { deleteDevice as deleteDeviceAPI, updateDevice as updateDeviceAPI, resetBurnin as resetBurninAPI } from '../../api/devices'
import moment from 'moment'
export default {
  name: 'DeviceRow',
  props: {
    device: Object,
    onDeviceDeleted: Function
  },
  data: function () {
    return {
      deviceState: {}
    }
  },
  mounted () {
    this.deviceState = { ...this.device }
  },
  computed: {
    isLegacy () {
      return this.device.SK_MODEL
    },
    isChanges () {
      return JSON.stringify(this.deviceState) !== JSON.stringify(this.device)
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    deleteDevice: function () {
      if (this.deviceState.SK_MODEL) return
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (!result.isConfirmed) return
        deleteDeviceAPI(this.deviceState.id).then(() => {
          this.$swal.fire(
            'Done!',
            'Device has been removed',
            'success'
          )
          this.onDeviceDeleted()
        })
      })
    },
    resetBurnin: function () {
      if (this.deviceState.SK_MODEL) return
      this.$swal({
        title: 'Are you sure you want to RESET Burnin status?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset it!'
      }).then(result => {
        if (!result.isConfirmed) return
        resetBurninAPI(this.deviceState.serial).then(() => {
          this.$swal.fire(
            'Done!',
            'Burnin has been reset for device',
            'success'
          )
        })
      })
    },
    updateDevice () {
      if (this.deviceState.SK_MODEL) return
      const fieldsChanged = Object.keys(this.deviceState).filter(key => this.deviceState[key] !== this.device[key])
      if (!fieldsChanged.length) return
      let text = 'Following fields have been updated:<br> <ul>'
      for (const field of fieldsChanged) {
        text += `<li><b>${field}</b>: <b>${this.device[field]}</b> => <b>${this.deviceState[field]}</b></li>`
      }
      text += '</ul>'

      this.$swal({
        title: 'Are you sure?',
        html: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update device!'
      }).then(result => {
        if (!result.isConfirmed) return
        updateDeviceAPI(this.deviceState.id, {
          licensemode: this.deviceState.licensemode,
          reference: this.deviceState.reference,
          comments: this.deviceState.comments,
          deviceoptions: this.deviceState.deviceoptions
        }).then(() => {
          this.$swal.fire(
            'Done!',
            'Device has been updated',
            'success'
          )
          this.onDeviceDeleted()
        })
      })
    },
    showLicenses () {
      this.$router.push('/assign-license?serial=' + this.deviceState.serial)
      /*
			const lics = []
			for (const l of this.deviceState.Licenses) {
				lics.push({ name: l.name, Type: l.license_id })
			}
			this.$swal.fire(
				'',
				JSON.stringify(lics),
				''
			)
			// JSON.stringify()
			*/
    }
  }
}
</script>

<style lang="scss" scoped>
td {
	vertical-align: middle;

	&:last-child {
		text-align: right;
	}
}
</style>
