import { createApp } from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Toaster from '@meforma/vue-toaster'
import App from './App.vue'
import router from './router'
import store from './store'
import '@tabler/core/dist/css/tabler.css'
import '@tabler/core/dist/js/tabler.js'
import 'bootstrap/dist/js/bootstrap.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import initIconLibrary from '@/iconLibrary'
import VueApexCharts from 'vue3-apexcharts'

initIconLibrary()

createApp(App)
	.use(VueApexCharts)
	.use(store)
	.use(router)
	.use(Toaster, { position: 'top-right' })
	.use(VueSweetalert2)
	.component('font-awesome-icon', FontAwesomeIcon)
	.mount('#app')
