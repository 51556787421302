import {
	library
} from '@fortawesome/fontawesome-svg-core'
import {
	faCubes,
	faHome,
	faFilter,
	faFileSignature,
	faSearch,
	faTrash,
	faSignOutAlt,
	faLifeRing,
	faSync,
	faUserShield,
	faUpload,
	faTablet,
	faCodeBranch,
	faCertificate,
	faSave,
	faFire,
	faFileContract,
	faSpinner,
	faSun,
	faMoon,
	faDharmachakra
} from '@fortawesome/free-solid-svg-icons'

const initIconLibrary = function () {
	library.add(faHome)
	library.add(faFileSignature)
	library.add(faSearch)
	library.add(faTrash)
	library.add(faSignOutAlt)
	library.add(faLifeRing)
	library.add(faCubes)
	library.add(faSync)
	library.add(faUserShield)
	library.add(faUpload)
	library.add(faTablet)
	library.add(faCodeBranch)
	library.add(faCertificate)
	library.add(faSave)
	library.add(faFire)
	library.add(faFileContract)
	library.add(faDharmachakra)
	library.add(faFilter)
	library.add(faSpinner)
	library.add(faSun)
	library.add(faMoon)
}

export default initIconLibrary
