<template>
	<div>
		<page-title title="manage hardware options" />
		<div class="card">
			<div class="card-body">
			<input
			v-model="query"
			placeholder="Search device type"
			class="form-control query"
			type="text"
		/>
					<table class="table table-xl mt-3">
			<thead>
				<tr>
					<th>Name</th>
					<th>Main Package</th>
					<th>Description</th>
					<th>Options</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="deviceType in deviceTypesToRender" :key="deviceType.id" @mouseleave="highlighted = null" @mouseover="highlighted = deviceType" :class="highlighted && highlighted.name === deviceType.name ? 'highlight' : ''">
					<td>{{deviceType.name}}</td>
					<td>{{deviceType.main_package}}</td>
					<td>{{deviceType.description}}</td>
					<td>
					<div v-if="deviceType?.options">
					<div style="margin-bottom: 5px; border-left: 2px solid #1e293b; border-radius: 5px; padding-left: 2px;" v-for="(option,index) of deviceType.options" :key="index">
							<input type="text" placeholder="Value" class="form-control" v-model="option.value">
							<textarea type="text" placeholder="Description" style="height: 30px;" class="form-control" v-model="option.description"></textarea>
						<button v-if="highlighted && highlighted.name === deviceType.name" class="btn btn-danger" style="height: 10px; font-size: 10px" @click="deviceType.options.splice(index, 1);">Remove</button>
					</div>
					</div>
						</td>
					<td style="width: 200px;">
						<button v-if="highlighted && highlighted.name === deviceType.name" style="margin-bottom: 10px;" class="btn btn-danger" @click="update(deviceType)">Update</button>
						<button v-if="highlighted && highlighted.name === deviceType.name" class="btn btn-success" @click="addOption(deviceType)">Add Option</button>
					</td>
				</tr>
			</tbody>
		</table>
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import { getDeviceTypes, updateDeviceType as updateDeviceTypeAPI } from '../api/device-types'
export default {
  data () {
    return {
      allDeviceTypes: [],
      deviceTypesToRender: [],
      highlighted: null,
      query: ''
    }
  },
  components: {
    PageTitle
  },
  methods: {
    updateDeviceTypeAPI,
    update (deviceType) {
      const data = { ...deviceType }
      if (data.options) {
        data.options = JSON.stringify(data.options)
      }
      updateDeviceTypeAPI(data).then((response) => {
        if (response && response.status === 200) {
          this.$toast.success(
            'Successfully updated: ' + response?.data?.name
          )
          this.loadDeviceTypes()
        } else {
          this.$toast.error(
            'Failed to update'
          )
        }
      })
    },
    addOption (deviceType) {
      if (!deviceType.options) deviceType.options = []
      deviceType.options.push({
        value: '',
        description: ''
      })
    },
    loadDeviceTypes () {
      getDeviceTypes().then(({ data: deviceTypes }) => {
        const dTypes = []
        for (const deviceType of deviceTypes || []) {
          if (deviceType.options) {
            deviceType.options = JSON.parse(deviceType.options)
          }
          dTypes.push(deviceType)
        }
        this.allDeviceTypes = dTypes
        this.deviceTypesToRender = dTypes
      })
    }
  },
  mounted () {
    this.loadDeviceTypes()
  },
  watch: {
    query: function (value) {
      this.deviceTypesToRender = this.allDeviceTypes.filter(
        i => i?.name.toLowerCase().includes(value.toLowerCase()) ||
				i?.main_package.toLowerCase().includes(value.toLowerCase()) ||
				i?.description.toLowerCase().includes(value.toLowerCase())
      )
    }
  }
}
</script>
<style scoped>
.highlight {
	background-color: #cccccc;
}
</style>
