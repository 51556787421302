<template>
	<div class="card card-body">
		<div class="d-flex align-items-center">
			<div class="subheader">System installs</div>
			<div class="ms-auto lh-1">
				<div class="dropdown" v-if="!loading">
					<a class="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						{{ CardTitle }}
					</a>
					<div class="dropdown-menu dropdown-menu-end">
						<a class="dropdown-item" :class="daysSelected == 7 ? 'active' : ''" @click="selectDays(7)">Last 7 days</a>
						<a class="dropdown-item" :class="daysSelected == 30 ? 'active' : ''" @click="selectDays(30)">Last 30 days</a>
						<a class="dropdown-item" :class="daysSelected == 90 ? 'active' : ''" @click="selectDays(90)">Last 90 days</a>
						<a class="dropdown-item" :class="daysSelected == 'all-time' ? 'active' : ''" @click="selectDays('all-time')">All time</a>
					</div>
				</div>
					<font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
			</div>
		</div>
		<div class="row align-items-center">
			<div class="col">
				<div>
					<span class="font-weight-medium">
						Reactor: {{ stats.reactor_installs_count }}
					</span>
				</div>
				<div>
					<span class="font-weight-medium">
						iBeam init: {{ stats.ibeam_init_installs_count }}
					</span>
				</div>
				<div>
					<span class="font-weight-medium">
						iBeam hardware: {{ stats.ibeam_hardware_installs_count }}
					</span>
				</div>
			</div>
			<div class="col-auto"></div>
		</div>
	</div>
</template>
<script>
import { api } from '@/api'

export default {
	name: 'ReactorAndIBeamInstalls',
	data () {
		return {
			daysSelected: 30,
			loading: false,
			stats: {}
		}
	},
	computed: {
		CardTitle () {
			if (this.daysSelected === 'all-time') return 'All time'
			return `Last ${this.daysSelected} days`
		}
	},
	methods: {
		selectDays (days) {
			this.daysSelected = days
			this.loadData()
		},
		loadData () {
			this.loading = true
			api.get('/stat/reactor-and-ibeam-stats?days=' + this.daysSelected).then(response => {
				this.loading = false
				if (response.data.Status === 'ok') {
					this.stats = response.data.Data
				}
			}).catch(e => {
					this.loading = false
					console.error(e.response)
				})
		}
	},
	mounted () {
		this.loadData()
	}
}
</script>
