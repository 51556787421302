<template>
	<page-title title="sign package" />
	<div class="row">
		<div class="col-xs-6 col-md-6 col-lg-4">
			<SignPackageForm />
		</div>
	</div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'
import SignPackageForm from '../components/SignPackageForm.vue'
export default {
  name: 'SignPackage',
  components: {
    SignPackageForm,
    PageTitle
  }
}
</script>
<style>
.card-title {
	font-weight: bold !important;
}
</style>
