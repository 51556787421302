<template>

	<div>
		<h2>Assign license: <h4 style="display:inline"><button v-if="hasUpdate" @click="getDevices(false)">Device List has changed! (Click to refresh)</button></h4></h2>
		<input
			v-model="query"
			placeholder="Search device type"
			class="form-control query"
			type="text"
		/>

		<div class="options-wrap">
			<div
				v-for="deviceType in deviceTypesToRender"
				v-bind:key="deviceType.id"
				@mousedown="selectDeviceType(deviceType)"
				class="option-item"
			>
				{{ deviceType.name }}
			</div>
			<div v-if="deviceTypesToRender.length === 0">
				No such device types found.
			</div>
		</div>

	<table class="table table-xl devices-wrap">
		<thead>
			<tr>
				<th>CPU id</th>
				<th>IP address</th>
				<th />
				<th />
			</tr>
		</thead>
		<tbody>
			<tr v-for="(device, index) in devices" v-bind:key="index">
				<td><b>{{ device.cpuid }}</b></td>
				<td><b>{{ device.currentIp }}</b></td>
				<td><button class="btn btn-primary" @click="identifyDevice(device)">Identify</button></td>
				<td><button class="btn btn-primary" @click="licenseDevice(device)">Assign license</button></td>
			</tr>
		</tbody>
	</table>
</div>
</template>

<script>
import { getDeviceTypes } from '../api/device-types'
import {
	getDevices as getDevicesAPI,
	identifyDevice as identifyDeviceAPI,
	licenseDevice as licenseDeviceAPI
} from '../api/devices'

export default {
	name: 'NewDeviceForm',
	data () {
		return {
			query: '',
			allDeviceTypes: [],
			deviceTypesToRender: [],
			devices: [],
			hasUpdate: false
		}
	},
	mounted () {
		getDeviceTypes().then(({ data: deviceTypes }) => {
			this.allDeviceTypes = deviceTypes?.sort((a, b) => a.name.localeCompare(b.name)) || []
			this.deviceTypesToRender = deviceTypes || []
		})

		this.getDevices(false)
		this.interval = setInterval(() => this.getDevices(true), 2000) // skips new devices, only shows button if differs
	},
	beforeUnmount () {
		clearInterval(this.interval)
	},
	computed: {
		selectedDevice: function () {
			return this.allDeviceTypes.find(i => i.name === this.query)
		}
	},
	methods: {
		selectDeviceType: function (deviceType) {
			this.query = deviceType.name
		},
		identifyDevice: function (device) {
			identifyDeviceAPI(device.cpuid)
				.then(() =>
					this.$toast.info(`Device ${device.cpuid} should identify itself for 3 seconds`))
				.catch(() =>
					this.$toast.error('Failed to start identify on device ' + device))
		},
		licenseDevice: async function (device) {
			if (!this.selectedDevice) {
				return this.$toast.error('You have to select device type first.')
			}

			let swalResponse = { value: {} }
			let options = ''
			options = this.selectedDevice?.options
			if (options) options = JSON.parse(options)
			try {
				let html = '<h1>Please enter following info:</h1>'
				html += `<h4>Selected device CPU Id: <span style="color: red">${device.cpuid}<span></h4>`
				html += '<input placeholder="Serial number" id="swal-input1" class="swal2-input"><br/>' +
					'<select id="swal-input2" class="swal2-input" >' +
					'<option value="">Base (Default)'
				if (options && options.length > 0) {
					for (const option of options) {
						html += `<option value="${option.value}">${option.value}`
					}
				}
				html += '</select>'
				if (options && options.length > 0) {
					for (const option of options) {
						html += `<li style="text-align: left"><b>${option.value}</b>: ${option.description}</li>`
					}
				}
				swalResponse = await this.$swal.fire({
					html: html,
					focusConfirm: false,
					width: '600px',
					showCancelButton: true,
					preConfirm: () => {
						return [
							document.getElementById('swal-input1').value,
							document.getElementById('swal-input2').value
						]
					}
				})
			} catch (e) {
				return
			}
			if (!swalResponse.isConfirmed) return

			const [serial, deviceoptions] = swalResponse.value

			if (!serial) {
				return this.$toast.error('Please enter valid serial number.')
			}
			if (!serial.match(/^[0-9]+$/)) {
				return this.$toast.error('Serial number is not valid. Should contain digits only.')
			}

			licenseDeviceAPI(
				device.cpuid,
				this.selectedDevice.id,
				serial,
				deviceoptions
			).then(() => {
					this.$toast.success('Device successfully licensed.')
				}).catch((error) => {
					this.$toast.error('Failed to license device: ' + error.response.data)
				})
		},
		getDevices (skipNew) {
			getDevicesAPI().then(({ data: Devices }) => {
				if (!Array.isArray(Devices)) {
					this.devices = []
					// this.hasUpdate = true // Not adding these two lines will make the last device just disappear...
					// if (skipNew) return
				}

				if (!Devices) {
					return
				}

				if (this.devices.length !== Devices.length) { // Not a very clever check!
					this.hasUpdate = true
					if (skipNew) return
				}

				this.devices = Devices.sort((a, b) => {
					return a.cpuid.toLowerCase() > b.cpuid.toLowerCase()
				})
				this.hasUpdate = false
			})
		}
	},
	watch: {
		query: function (value) {
			this.deviceTypesToRender = this.allDeviceTypes.filter(
				i => i.name.toLowerCase().includes(value.toLowerCase())
			)
		}
	}
}

</script>

<style lang="scss">
.query {
	padding: 4px;
	font-size: 14px;
}
.query:focus + .options-wrap {
	display: block;
}
.options-wrap {
	display: none;
	max-height: 300px;
	width: 250px;
	overflow-y: auto;
	position: absolute;
	background-color: #fff;
	box-shadow: 0 1px 10px -2px rgba(13, 47, 74, 0.8);
	border-radius: 4px;
	padding: 4px;

	.option-item {
		padding: 4px;
		border-bottom: 1px solid silver;
		color: #2e77c6;
		cursor: pointer;
		&:last-child {
			border: none;
		}
	}
}
.devices-wrap {
	margin-top: 50px;

	td {
		vertical-align: middle;
	}
}
</style>
