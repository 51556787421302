<template>
	<tr>
		<td width='25%'>{{client.serial}}</td>
		<td width='25%'>{{client.devicetype}}</td>
		<td width='25%'>{{client.reference}}</td>
		<td width='25%'>
			<div class="btn-group">
				<button type="button" class="btn btn-success" @click.exact="connectToClient()"
					@click.shift="connectToClient(true)">
					Connect To Client
				</button>
				<button type="button" class="btn btn-warning" @click="disconnectClient()">Disable RC</button>
			</div>
			<form action="https://rc.skaarhoj.com/uapi/disablerc" ref="form" method="POST"></form>
		</td>
	</tr>
</template>

<script setup>
import { defineProps, getCurrentInstance, ref } from 'vue'

const vue = getCurrentInstance()

const props = defineProps({
	client: { type: Object, required: true }
})

const form = ref(null)

async function disconnectClient () {
	const result = await vue?.proxy.$swal.fire({
		icon: 'warning',
		title: 'Are you sure? You are about to disable remote control for: ' + props.client.serial,
		showCancelButton: true
	})
	if (!result.isConfirmed) return
	document.cookie = 'deviceselect=' + props.client.serial + ';path=/;domain=.skaarhoj.com'
	form.value.submit()
}

function connectToClient (shift) {
	document.cookie = 'deviceselect=' + props.client.serial + ';path=/;domain=.skaarhoj.com'
	if (!shift) {
		window.location.href = 'https://rc.skaarhoj.com'
	} else {
		window.open('https://rc.skaarhoj.com', '_blank')
	}
}
</script>
