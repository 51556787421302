<template>
<div>
	<div class="card">
		<div class="card-header">
			<h3 class="card-title">GPG Sign</h3>
		</div>
		<div class="card-body">
			<input type="file" accept=".ipk" ref="file" />
			<div class="progress mt-3 hidden" id="progress">
				<div class="progress-bar progress-bar-indeterminate bg-green"></div>
			</div>
		</div>
		<div class="card-footer">
			<button class="btn btn-success" style="float:right" @click="submitFile()">Sign Package with GPG</button>
		</div>
	</div>
</div>
</template>

<script>
import {
  signPackage
} from '../api/packages'
export default {
  name: 'SignPackageForm',
  components: {},
  methods: {
    submitFile () {
      const file = this.$refs.file.files[0]
      if (file) {
        const formData = new FormData()
        const progressBar = document.getElementById('progress')
        progressBar.classList.remove('hidden')
        formData.append('file', file)
        signPackage(formData).then((response) => {
          const fileName = file.name.replace('.ipk', '.ipks')
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          progressBar.classList.add('hidden')
        }).catch((err) => {
          progressBar.classList.add('hidden')
          this.$toast.error(err)
        })
      } else {
        this.$toast.error('Please upload file before signing it!')
      }
    }
  }
}
</script>
<style>
.card-title {
	font-weight: bold !important;
}

.hidden {
	display: none !important;
}
</style>
