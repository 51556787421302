<template>
  <div>
    <page-title title="license types" />
    <div class="card">
      <div class="card-body">
        <div
          id="licenseTypesModal"
          tabindex="-1"
          style="display: none"
          class="modal modal-blur fade"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit License Type</h5>
                <button
                  @click="cancel()"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body" v-if="selected">
                <div class="mb-1">
                  <label class="form-label">Name</label>
                  <input class="form-control" v-model="selected.name" />
                </div>
                <div class="mb-1">
                  <label class="form-label">Description</label>
                  <textarea class="form-control" v-model="selected.description" />
                </div>
                <div>
                  <label class="form-label">Key</label>
                  <input class="form-control" v-model="selected.key" />
                </div>
                <div>
                  <label class="form-label">Options (JSON Object, <a target="_blank" href="https://wiki.skaarhoj.com/books/services/page/init-server-license-types">see wiki here</a>)</label>
                  <input class="form-control" v-model="selected.options" />
                </div>
                <div>
                  <label class="form-label">Price EU</label>
                  <input class="form-control" type="number" v-model.number="selected.price_eu" />
                </div>
                <div>
                  <label class="form-label">Price US</label>
                  <input class="form-control" type="number" v-model.number="selected.price_us" />
                </div>
                <div>
                  <label class="form-label">Controller Types</label>
                  <input
                    class="form-control"
                    v-model="selected.controller_types"
                  />
                </div>
								<div>
                  <label class="form-label">Allow Multiassign</label>
                  <input class="" type="checkbox" v-model="selected.multiassign" />
                </div>
              </div>
              <div class="modal-footer" style="justify-content: left;">
                <button type="button" class="btn btn-danger" @click="remove()">
                  Remove
                </button>
                <button type="button" class="btn" style="margin-left: auto" @click="cancel()">
                  Close
                </button>
                <button type="button" class="btn btn-primary" @click="update()">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          id="licenseTypesModalCreate"
          tabindex="-1"
          style="display: none"
          class="modal modal-blur fade"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Create License Type</h5>
                <button
                  @click="cancel()"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="mb-1">
                  <label class="form-label">Name</label>
                  <input class="form-control" v-model="newLicense.name" />
                </div>
                <div class="mb-1">
                  <label class="form-label">Description</label>
                  <textarea class="form-control" v-model="newLicense.description" />
                </div>
                <div>
                  <label class="form-label">Key</label>
                  <input class="form-control" v-model="newLicense.key" />
                </div>
                <div>
                  <label class="form-label">Options (JSON Object, <a target="_blank" href="https://wiki.skaarhoj.com/books/services/page/init-server-license-types">see wiki here</a>)</label>
                  <input class="form-control" v-model="newLicense.options" />
                </div>
                <div>
                  <label class="form-label">Price EU</label>
                  <input class="form-control" type="number" v-model.number="newLicense.price_eu" />
                </div>
                <div>
                  <label class="form-label">Price US</label>
                  <input class="form-control" type="number" v-model.number="newLicense.price_us" />
                </div>
                <div>
                  <label class="form-label">Controller Types</label>
                  <input
                    class="form-control"
                    v-model="newLicense.controller_types"
                  />
                </div>
								<div>
                  <label class="form-label">Allow Multiassign</label>
                  <input class="" type="checkbox" v-model="newLicense.multiassign" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn" @click="cancel()">
                  Close
                </button>
                <button type="button" class="btn btn-primary" @click="insert()">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
				<div class="input-group mb-2">
					<input
						v-model="query"
						placeholder="Search license type"
						class="form-control query"
						type="text"
					/>
          <button class="btn btn-success" @click="create()" type="button">Create</button>
        </div>
        <table class="table table-xl mt-3">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Key</th>
              <th>Options</th>
              <th>Price EU</th>
              <th>Price US</th>
              <th>Controller Types</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="table-row"
              @click="edit(license)"
              v-for="license of licenseTypesToRender"
              :key="license"
              onmouseover="this.classList.add('table-info')"
              onmouseout="this.classList.remove('table-info')"
            >
              <td>{{ license.name }}</td>
              <td>{{ license.description }}</td>
              <td>{{ license.key }}</td>
              <td>{{ license.options }}</td>
              <td>{{ license.price_eu }}</td>
              <td>{{ license.price_us }}</td>
              <td>{{ license.controller_types }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'
import { api } from '@/api'
import moment from 'moment'
import $ from 'jquery'
export default {
  data () {
    return {
      licenseTypes: {},
      licenseTypesToRender: null,
      query: '',
      selected: null,
      newLicense: { options: '[]' }
    }
  },
  components: {
    PageTitle
  },
  watch: {
    query: function (data) {
      this.licenseTypesToRender = Object.fromEntries(
        Object.entries(this.licenseTypes).filter(
          ([key, value]) =>
            value.name.toLowerCase().includes(data) ||
            value.description.toLowerCase().includes(data)
        )
      )
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    edit (license) {
      this.selected = { ...license }
      this.selected.options = JSON.stringify(license.options)
			if (this.selected.options.startsWith('"')) {
				this.selected.options = this.selected.options.substring(1)
			}
			if (this.selected.options.endsWith('"')) {
				this.selected.options = this.selected.options.slice(0, -1)
			}

      $('#licenseTypesModal').modal('show')
    },
    create () {
      this.selected = { options: '[]' }
      this.newLicense = { options: '[]' }
      $('#licenseTypesModalCreate').modal('show')
    },
    update () {
      try {
        this.selected.options = JSON.parse(this.selected.options)
        if (!Array.isArray(this.selected.options)) {
          this.$toast.error('options need to be an array of objects')
          return
        }
      } catch (e) {
        this.$toast.error('Check options field ' + e)

        return
      }
      api.put('/license-type', this.selected).then(() => {
        $('#licenseTypesModal').modal('hide')
        this.loadLicenseTypes()
      }).catch(() => {
        this.$toast.error('Error happened during save')
      })
    },
    insert () {
      try {
        this.selected.options = JSON.parse(this.selected.options)
        if (!Array.isArray(this.selected.options)) {
          this.$toast.error('options need to be an array of objects')
          return
        }
      } catch (e) {
        this.$toast.error('Check options field ' + e)
        return
      }
      api.post('/license-type', this.newLicense).then(() => {
        $('#licenseTypesModalCreate').modal('hide')
        this.loadLicenseTypes()
        this.newLicense = { options: {} }
      }).catch(() => {
        this.$toast.error('Error happened during save')
      })
    },
    remove () {
      api.delete('/license-types/' + this.selected.id).then(() => {
        $('#licenseTypesModal').modal('hide')
        this.loadLicenseTypes()
      }).catch(() => {
        this.$toast.error('Error happened during save')
      })
    },
    cancel () {
      $('#licenseTypesModalCreate').modal('hide')
      $('#licenseTypesModal').modal('hide')
    },
    loadLicenseTypes () {
      api.get('/license-types').then((response) => {
        if (response.data.Status !== 'ok') return
        this.licenseTypes = response.data.Data
        if (!this.query) this.licenseTypesToRender = this.licenseTypes
      })
    }
  },
  mounted () {
    this.loadLicenseTypes()
  }
}
</script>
<style scoped>
.table-row:hover {
  cursor: pointer;
}
</style>
