<template>
	<div class="card card-sm">
		<div class="card-body">
			<div class="row align-items-center">
				<div class="col-auto">
					<span class="bg-primary text-white avatar">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-briefcase" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<rect x="3" y="7" width="18" height="13" rx="2"></rect>
							<path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2"></path>
							<line x1="12" y1="12" x2="12" y2="12.01"></line>
							<path d="M3 13a20 20 0 0 0 18 0"></path>
						</svg>
					</span>
				</div>
				<div class="col">
					<div class="font-weight-medium">{{count}}</div>
					<div class="text-muted">Organizations Created</div>
				</div>
				<div class="col-auto">
					<font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api'

const loading = ref(false)
const count = ref(0)

function fetchData () {
	loading.value = true
	api.get('/stat/organizations-created')
		.then(response => {
			loading.value = false
			if (response.data.Status === 'ok') {
				count.value = response.data.Data
			}
		}).catch(error => {
			loading.value = false
			console.error(error)
		})
}

onMounted(() => {
	fetchData()
})
</script>
