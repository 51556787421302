<template>
	<div class="card card-sm">
		<div class="card-body">
			<div class="row align-items-center">
				<div class="col-auto">
					<span class="bg-primary text-white avatar">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-license" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11"></path>
							<line x1="9" y1="7" x2="13" y2="7"></line>
							<line x1="9" y1="11" x2="13" y2="11"></line>
						</svg>
					</span>
				</div>
				<div class="col">
					<div class="font-weight-medium">{{countrel}}/{{countall}}</div>
					<div class="text-muted">Released Cores</div>
				</div>
				<div class="col-auto">
					<font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api'

const loading = ref(false)
const countrel = ref(0)
const countall = ref(0)

function fetchData () {
	loading.value = true
	api.get('/stat/cores-released-mature')
		.then(response => {
			loading.value = false
			if (response.data.Status === 'ok') {
				countrel.value = response.data.Data.Released
				countall.value = response.data.Data.Count
			}
		})
		.catch(error => {
			loading.value = false
			console.error(error)
		})
}

onMounted(() => {
	fetchData()
})
</script>
