<template>
	<div>
		<page-title title="Home" />
		<div class="row p-0">
			<div class="col-md-4 mb-3"><UsersRegisteredChart /></div>
			<div class="col-md-4 mb-3"><ControllersRegisteredChart /></div>
			<div class="col-md-4 mb-3"><PackagesPublishedChart /></div>
		</div>
		<div class="row">
			<div class="col-md-4 mb-3"><UsersRegistered /></div>
			<div class="col-md-4 mb-3"><OrganizationsCreated /></div>
			<div class="col-md-4 mb-3"><DevicesRegistered /></div>
			<div class="col-md-4 mb-3"><LicenseTypes /></div>
			<div class="col-md-4 mb-3"><DeviceTypes /></div>
			<div class="col-md-4 mb-3"><PurchasedLicenses /></div>
			<div class="col-md-4 mb-3"><CoreConfigCount /></div>
			<div class="col-md-4 mb-3"><CoresStatus /></div>
			<div class="col-md-4 mb-3"><CoresStatusMore /></div>
			<div class="col-md-4 mb-3"><ModelsReleased /></div>
			<div class="col-md-4 mb-3"><RemoteSupportAmount /></div>

			<div class="col-md-4"></div>
			<div class="col-md-4 mb-3"><CoresInstalled /></div>
			<div class="col-md-4 mb-3"><ReactorAndIBeamInstalls /></div>
			<div class="col-md-4 mb-3"><OfflineOnlineInstalls /></div>
		</div>
	</div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'
import UsersRegisteredChart from '../components/charts/UsersRegisteredChart.vue'
import ControllersRegisteredChart from '../components/charts/ControllersRegistered.vue'
import PackagesPublishedChart from '../components/charts/PackagesPublishedChart.vue'
import UsersRegistered from '../components/charts/UsersRegistered.vue'
import OrganizationsCreated from '../components/charts/OrganizationsCreated.vue'
import DevicesRegistered from '../components/charts/DevicesRegistered.vue'
import LicenseTypes from '../components/charts/LicenseTypes.vue'
import DeviceTypes from '../components/charts/DeviceTypes.vue'
import PurchasedLicenses from '../components/charts/PurchasedLicenses.vue'
import CoreConfigCount from '../components/charts/CoreConfigCount.vue'
import CoresStatus from '../components/charts/CoresStatus.vue'
import CoresStatusMore from '../components/charts/CoresStatusMore.vue'
import CoresInstalled from '../components/charts/CoresInstalled.vue'
import ReactorAndIBeamInstalls from '../components/charts/ReactorAndIBeamInstalls.vue'
import OfflineOnlineInstalls from '../components/charts/OfflineOnlineInstalls.vue'
import ModelsReleased from '../components/charts/ModelsReleased.vue'
import RemoteSupportAmount from '../components/charts/RemoteSupportAmount.vue'

export default {
	name: 'Home',
	components: {
		PageTitle,
		UsersRegisteredChart,
		ControllersRegisteredChart,
		CoresInstalled,
		PackagesPublishedChart,
		UsersRegistered,
		OrganizationsCreated,
		DevicesRegistered,
		LicenseTypes,
		DeviceTypes,
		ModelsReleased,
		RemoteSupportAmount,
		PurchasedLicenses,
		CoreConfigCount,
		CoresStatus,
		CoresStatusMore,
		OfflineOnlineInstalls,
		ReactorAndIBeamInstalls
	}
}
</script>
