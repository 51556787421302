import Axios from 'axios'
import store from '../store'

export const API_URL = process.env.VUE_APP_API_URL
const AUTH_URL = process.env.VUE_APP_AUTH_URL
export const API_PREFIX = '/api'
const AUTH_PREFIX = '/auth'

export const authServer = Axios.create({
	baseURL: AUTH_URL + API_PREFIX
})
export const api = Axios.create({
	baseURL: API_URL + API_PREFIX
})

export const auth = Axios.create({
	baseURL: API_URL + AUTH_PREFIX
})

api.defaults.withCredentials = true
auth.defaults.withCredentials = true
authServer.defaults.withCredentials = true
api.interceptors.response.use((response) => {
	return response
}, async function (error) {
	const originalRequest = error.config
	if (error?.response?.status === 403 && !originalRequest._retry) {
		originalRequest._retry = true
		await authServer.post('/refreshtoken').then(() => {
			api(originalRequest)
			window.location.reload()
		}).catch(() => {
			store.commit('logout')
		})
	}
	return Promise.reject(error)
})
