<template>
	<div class="card card-sm">
		<div class="card-body">
			<div class="row align-items-center">
				<div class="col-auto">
					<span class="bg-primary text-white avatar">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<circle cx="9" cy="7" r="4"></circle>
							<path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
							<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
							<path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
						</svg>
					</span>
				</div>
				<div class="col">
					<div class="font-weight-medium">{{count}}</div>
					<div class="text-muted">Users Registered</div>
				</div>
				<div class="col-auto">
					<font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api'

const loading = ref(false)
const count = ref(0)

function fetchData () {
	loading.value = true
	api.get('/stat/users-registered')
		.then(response => {
			loading.value = false
			if (response.data.Status === 'ok') {
				count.value = response.data.Data
			}
		})
		.catch(error => {
			loading.value = false
			console.error(error)
		})
}

onMounted(() => {
	fetchData()
})
</script>
