<template>
	<div class="page">
			<aside class="navbar navbar-vertical navbar-expand-lg" v-if="user">
				<div class="container-fluid">
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
						<span class="navbar-toggler-icon"></span>
					</button>
					<h1 class="navbar-brand navbar-brand-autodark">
						<a href="/">
							<img src="./assets/img/skaarhoj_logo.png" style="height: 20px;" alt="SKAARHOJ" class="navbar-brand-image">
						</a>
					</h1>
					<div class="collapse navbar-collapse" id="navbar-menu">
						<ul class="navbar-nav pt-lg-3 card">
							<li class="nav-item">
								<router-link to="/" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="home" />
									</span>
									<span class="nav-link-title">
										Home
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isDeviceAssignment">
								<router-link to="/device-assignment" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="tablet" />
									</span>
									<span class="nav-link-title">
										Device assignment
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isSerialNumbers">
								<router-link class="nav-link" to="/serial-number-search">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="search" />
									</span>
									<span class="nav-link-title">
										Serial numbers
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isSignPackage">
								<router-link to="/sign-package" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="file-signature" />
									</span>
									<span class="nav-link-title">
										Sign Package
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isManagePackages">
								<router-link to="/package-management" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="cubes" />
									</span>
									<span class="nav-link-title">
										Package management
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isRemoteSupport">
								<router-link to="/remote-support" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="life-ring" />
									</span>
									<span class="nav-link-title">
										Remote Support
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isManageOptions">
								<router-link to="/manage-options" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="user-shield" />
									</span>
									<span class="nav-link-title">
										Manage HW Options
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isSystemUpdates">
								<router-link to="/system-updates" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="upload" />
									</span>
									<span class="nav-link-title">
										System Updates
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isCoreVersions">
								<router-link to="/core-versions" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="code-branch" />
									</span>
									<span class="nav-link-title">
										Core Versions
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isLicenseTypes">
								<router-link to="/license-types" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="certificate" />
									</span>
									<span class="nav-link-title">
										License Types
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isDeviceTypes">
								<router-link to="/device-types" class="nav-link">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="tablet" />
									</span>
									<span class="nav-link-title">
										Device Types
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isAssignLicense">
								<router-link class="nav-link" to="/assign-license">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="file-contract" />
									</span>
									<span class="nav-link-title">
										Assign a License
									</span>
								</router-link>
							</li>
							<li class="nav-item" v-if="isArtifacts">
								<router-link class="nav-link" to="/artifacts">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="dharmachakra" />
									</span>
									<span class="nav-link-title">
										Artifacts
									</span>
								</router-link>
							</li>
							<li class="nav-item">
								<div class="nav-link" style="cursor: pointer;" v-on:click="initLogout()">
									<span class="nav-link-icon d-md-none d-lg-inline-block">
										<font-awesome-icon icon="sign-out-alt" />
									</span>
									<span class="nav-link-title">
										Log out
									</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</aside>
			<div class="content">
				<div class="container-xl">
					<router-view/>
				</div>
				<footer class="footer footer-transparent d-print-none">
					<div class="container">
						<div class="row text-center align-items-center flex-row-reverse">
							<div class="col-lg-auto ms-lg-auto">
								<ul class="list-inline list-inline-dots mb-0">
									<a href="#" class="mx-2" @click="toggleTheme">
										<font-awesome-icon :icon="ResolveThemeIcon" class="link-secondary" />
									</a>
									<a href="https://skaarhoj.com/support" class="link-secondary">Support</a>
								</ul>
							</div>
							<div class="col-12 col-lg-auto mt-3 mt-lg-0">
								<ul class="list-inline list-inline-dots mb-0">
									<li class="list-inline-item">
										Copyright © {{ new Date().getFullYear() }} SKAARHOJ
									</li>
								</ul>
							</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	name: 'MainComponent',
	computed: {
		...mapState(['user', 'theme']),
		ResolveThemeIcon () {
			return this.theme === 'dark' ? 'fa-moon' : 'fa-sun'
		},
		isSuperAdmin () {
			return this.user?.Role?.find((x) => x.role === 'SUPER_ADMIN')
		},
		isManageOptions () {
			return this.user?.Role?.find((x) => x.role === 'INIT_MANAGE_OPTIONS') || this.isSuperAdmin
		},
		isSystemUpdates () {
			return this.user?.Role?.find((x) => x.role === 'INIT_SYSTEM_UPDATES') || this.isSuperAdmin
		},
		isManagePackages () {
			return this.user?.Role?.find((x) => x.role === 'INIT_MANAGE_PACKAGES') || this.isSuperAdmin
		},
		isSignPackage () {
			return this.user?.Role?.find((x) => x.role === 'INIT_SIGN_PACKAGE') || this.isSuperAdmin
		},
		isRemoteSupport () {
			return this.user?.Role?.find((x) => x.role === 'INIT_REMOTE_SUPPORT') || this.isSuperAdmin
		},
		isSerialNumbers () {
			return this.user?.Role?.find((x) => x.role === 'INIT_SERIAL_NUMBERS') || this.isSuperAdmin
		},
		isDeviceAssignment () {
			return this.user?.Role?.find((x) => x.role === 'INIT_DEVICE_ASSIGNMENT') || this.isSuperAdmin
		},
		isCoreVersions () {
			return this.user?.Role?.find((x) => x.role === 'INIT_CORE_VERSIONS') || this.isSuperAdmin
		},
		isLicenseTypes () {
			return this.user?.Role?.find((x) => x.role === 'INIT_LICENSE_TYPES') || this.isSuperAdmin
		},
		isDeviceTypes () {
			return this.user?.Role?.find((x) => x.role === 'INIT_DEVICE_TYPES') || this.isSuperAdmin
		},
		isAssignLicense () {
			return this.user?.Role?.find((x) => x.role === 'INIT_LICENSE_TYPES') || this.isSuperAdmin
		},
		isArtifacts () {
			return this.user?.Role?.find((x) => x.role === 'INIT_SERIAL_NUMBERS') || this.isSuperAdmin
		}
	},
	methods: {
		...mapActions(['logout']),
		...mapMutations(['toggleTheme', 'setTheme']),
		initLogout () {
			this.logout()
		},
		listenSystemThemeChange () {
			window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
				this.setTheme(event.matches ? 'dark' : 'light')
			})
		}
	},
	mounted () {
		this.listenSystemThemeChange()
	}
}
</script>

<style lang="scss">
#app {
	height: 100%;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@media (min-width: 992px) {
	.navbar-expand-lg.navbar-vertical~.content, .navbar-expand-lg.navbar-vertical~.navbar {
		-webkit-margin-start: 15rem;
		margin-inline-start: 15rem;
	}
}
.page, .content {
	height: 100%;
}
.content {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}
.footer {
	height: 84px;
}
.navbar-nav {
	position: absolute;
	z-index: 2;
	border: none !important;
}
</style>

<style lang="scss">
body[data-bs-theme=dark] {
	.swal2-popup {
		background-color: var(--tblr-body-bg);
		color: var(--tblr-body-color);
	}
	::selection {
		background-color: #5AC8FA;
		color: black;
	}
}

.apexcharts-svg {
	background-color: transparent !important;
}
</style>
