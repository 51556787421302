<template>
  <page-title title="Device assignment" />
    <div class="card card-body">
      <new-device-form />
    </div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'
import NewDeviceForm from '../components/NewDeviceForm.vue'

export default {
  name: 'DeviceAssignment',
  components: {
    NewDeviceForm,
    PageTitle
  }
}
</script>
