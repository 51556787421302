import { api } from '.'

export function signPackage (formData) {
  return api.post('/sign-package', formData, { responseType: 'arraybuffer' })
}

export function uploadPackage (file, onUploadProgress = () => {}) {
  const formData = new FormData()
  formData.append('package', file)

  return api.post('/upload-package', formData, { onUploadProgress })
}

export function getPackages (query, statuses) {
  return api.get('/packages', { params: { query, statuses } })
}

export function updatePackagesStatus (ids, status) {
  return api.post('/update-packages-status', null, { params: { ids, status } })
}

export function publishIndex () {
  return api.post('/publish-index')
}

export function deletePackages (ids) {
  return api.delete('/packages', { params: { ids } })
}

export function getIndexHealth () {
  return api.get('/indexhealth')
}
