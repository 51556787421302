<template>
<div></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'Sso',
	methods: {
		...mapActions(['verifySSO'])
	},
	async mounted () {
		try {
			await this.verifySSO()
		} catch (e) {
			console.error(e)
			this.$router.push('/signin')
		}
	}
}
</script>
