<template>
  <div>
    <page-title title="device types" />
    <div class="card">
      <div class="card-body">
        <div
          id="deviceTypesModal"
          tabindex="-1"
          style="display: none"
          class="modal modal-blur fade"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Device Type</h5>
                <button
                  @click="cancel()"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body" v-if="selected">
                <div class="mb-1">
                  <label class="form-label">Name</label>
                  <input class="form-control" v-model="selected.name" />
                </div>
                <div class="mb-1">
                  <label class="form-label">Main Package</label>
                  <input class="form-control" v-model="selected.main_package" />
                </div>
                <div class="mb-1">
                  <label class="form-label">Description</label>
                  <textarea
                    class="form-control"
                    v-model="selected.description"
                  />
                </div>
                <div>
                  <label class="form-label">Additional Packages</label>
                  <input
                    class="form-control"
                    v-model="selected.additional_packages"
                  />
                </div>
                <div>
                  <label class="form-label">Hardware Config TXT</label>
                  <input
                    class="form-control"
                    v-model="selected.hardware_config_txt"
                  />
                </div>
                <div>
                  <label class="form-label">Platform</label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="selected.platform"
                  />
                </div>
                <div>
                  <label class="form-label">Hidden</label>
                  <input class="" type="checkbox" v-model="selected.hidden" />
                </div>
                <div>
                  <label class="form-label"
                    >Default License Options (JSON Object,
                    <a
                      target="_blank"
                      href="https://wiki.skaarhoj.com/books/services/page/init-server-license-types"
                      >see wiki here</a
                    >)</label
                  >
                  <input
                    class="form-control"
                    v-model="selected.default_license_options"
                  />
                </div>
                <div>
                  <label class="form-label">Store Name</label>
                  <input
                    class="form-control"
                    type="input"
                    v-model="selected.store_name"
                  />
                </div>
                <div>
                  <label class="form-label">Store Categorys</label>
                  <input
                    class="form-control"
                    type="input"
                    v-model="selected.store_categorys"
                  />
                </div>
                <div>
                  <label class="form-label">Config Classes</label>
                  <input
                    class="form-control"
                    type="input"
                    v-model="selected.config_classes"
                  />
                </div>
                <div>
                  <label class="form-label">SVG</label>
                  <textarea
                    class="form-control"
                    type="input"
                    v-model="selected.svg"
                  />
                </div>
                <div>
                  <label class="form-label">Options</label>
                  <input
                    disabled
                    class="form-control"
                    type="input"
                    v-model="selected.options"
                  />
                </div>
                <div>
                  <label class="form-label">LicenseMode</label>
                  <select class="form-control" v-model="selected.licensemode">
                    <option></option>
                    <option>explicit</option>
                    <option>explicitcores</option>
                  </select>
                </div>
              </div>
              <div class="modal-footer" style="justify-content: left">
                <button type="button" class="btn btn-danger" @click="remove()">
                  Remove
                </button>
                <button
                  type="button"
                  class="btn"
                  style="margin-left: auto"
                  @click="cancel()"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary" @click="update()">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          id="deviceTypesModalCreate"
          tabindex="-1"
          style="display: none"
          class="modal modal-blur fade"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Create Device Type</h5>
                <button
                  @click="cancel()"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body" v-if="newDeviceType">
                <div class="mb-1">
                  <label class="form-label">Name</label>
                  <input class="form-control" v-model="newDeviceType.name" />
                </div>
                <div class="mb-1">
                  <label class="form-label">Main Package</label>
                  <input
                    class="form-control"
                    v-model="newDeviceType.main_package"
                  />
                </div>
                <div class="mb-1">
                  <label class="form-label">Description</label>
                  <textarea
                    class="form-control"
                    v-model="newDeviceType.description"
                  />
                </div>
                <div>
                  <label class="form-label">Additional Packages</label>
                  <input
                    class="form-control"
                    v-model="newDeviceType.additional_packages"
                  />
                </div>
                <div>
                  <label class="form-label">Hardware Config TXT</label>
                  <input
                    class="form-control"
                    v-model="newDeviceType.hardware_config_txt"
                  />
                </div>
                <div>
                  <label class="form-label">Platform</label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="newDeviceType.platform"
                  />
                </div>
                <div>
                  <label class="form-label">Hidden</label>
                  <input
                    class=""
                    type="checkbox"
                    v-model="newDeviceType.hidden"
                  />
                </div>
                <div>
                  <label class="form-label"
                    >Default License Options (JSON Object,
                    <a
                      target="_blank"
                      href="https://wiki.skaarhoj.com/books/services/page/init-server-license-types"
                      >see wiki here</a
                    >)</label
                  >
                  <input
                    class="form-control"
                    v-model="newDeviceType.default_license_options"
                  />
                </div>
                <div>
                  <label class="form-label">Store Name</label>
                  <input
                    class="form-control"
                    type="input"
                    v-model="newDeviceType.store_name"
                  />
                </div>
                <div>
                  <label class="form-label">Store Categorys</label>
                  <input
                    class="form-control"
                    type="input"
                    v-model="newDeviceType.store_categorys"
                  />
                </div>
                <div>
                  <label class="form-label">Config Classes</label>
                  <input
                    class="form-control"
                    type="input"
                    v-model="newDeviceType.config_classes"
                  />
                </div>
                <div>
                  <label class="form-label">SVG</label>
                  <textarea
                    class="form-control"
                    type="input"
                    v-model="newDeviceType.svg"
                  />
                </div>
                <div>
                  <label class="form-label">Hardware Options</label>
                  <input
                    disabled
                    class="form-control"
                    type="input"
                    v-model="newDeviceType.options"
                  />
                </div>
                <div>
                  <label class="form-label">LicenseMode</label>
                  <select
                    class="form-control"
                    v-model="newDeviceType.licensemode"
                  >
                    <option></option>
                    <option>explicit</option>
                    <option>explicitcores</option>
                  </select>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn" @click="cancel()">
                  Close
                </button>
                <button type="button" class="btn btn-primary" @click="insert()">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="input-group mb-2">
          <input
            v-model="query"
            placeholder="Search device type"
            class="form-control query"
            type="text"
          />
          <button class="btn btn-success" @click="create()" type="button">
            Create
          </button>
        </div>
				<div class="container" style="overflow-x: auto; box-shadow: inset -18px 0px 17px -6px rgba(0,0,0,0.1);">
					<table class="table table-xl table-responsive mt-3">
						<thead>
							<tr>
								<th>Name</th>
								<th>Main Package (DeviceType)</th>
								<th>Description</th>
								<th>Created</th>
								<th>Additional Pkgs</th>
								<th>Platform</th>
								<th>Licensemode</th>
								<th>Hidden</th>
								<th>Store Name</th>
								<th>Store Categorys</th>
								<th>Config Classes</th>
								<th>SVG</th>
								<th>Options</th>
							</tr>
						</thead>
						<tbody>
							<tr
								class="table-row"
								@click="edit(tp)"
								v-for="tp of deviceTypesToRender"
								:key="tp"
								onmouseover="this.classList.add('table-info')"
								onmouseout="this.classList.remove('table-info')"
							>
								<td>{{ tp.name }}</td>
								<td>{{ tp.main_package }} (<a @click.stop.prevent="dlPackage(tp.main_package)" target="_blank">Download</a>)</td>
								<td>{{ tp.description }}</td>
								<td>{{ formatDate(tp.created_at) }}</td>
								<td>{{ tp.additional_packages }}</td>
								<td>{{ tp.platform }}</td>
								<td>{{ tp.licensemode }}</td>
								<td>{{ tp.hidden }}</td>
								<td>{{ tp.store_name }}</td>
								<td>{{ tp.store_categorys }}</td>
								<td>{{ tp.config_classes }}</td>
								<td>{{ tp.svg ? "Yes" : "No" }}</td>
								<td>{{ tp.options }}</td>
							</tr>
						</tbody>
					</table>
				</div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'
import { api } from '@/api'
import moment from 'moment'
import $ from 'jquery'
export default {
	data () {
		return {
			deviceTypes: {},
			deviceTypesToRender: null,
			query: '',
			selected: null,
			newDeviceType: {}
		}
	},
	components: {
		PageTitle
	},
	watch: {
		query: function (data) {
			this.deviceTypesToRender = Object.fromEntries(
				Object.entries(this.deviceTypes).filter(
					([key, value]) =>
						value.name.toLowerCase().includes(data) ||
						value.description.toLowerCase().includes(data) ||
						value.main_package.toLowerCase().includes(data)
				)
			)
		}
	},
	methods: {
		dlPackage (name) {
			window.open('/api/download-signed-hw-package/' + name, '_blank')
		},
		formatDate (date) {
			return moment(date).format('DD-MM-YYYY HH:mm')
		},
		edit (type) {
			this.selected = { ...type }
			this.selected.default_license_options = JSON.stringify(this.selected.default_license_options)
			$('#deviceTypesModal').modal('show')
		},
		create () {
			$('#deviceTypesModalCreate').modal('show')
		},
		update () {
			try {
				this.selected.default_license_options = JSON.parse(this.selected.default_license_options)
				if (!Array.isArray(this.selected.default_license_options)) {
					this.$toast.error('default_license_options need to be an array of objects')
					this.selected.default_license_options = JSON.stringify(this.selected.default_license_options)
					return
				}
			} catch (e) {
				this.$toast.error('Check options field ' + e)
				return
			}
			api.put('/device-type', this.selected).then(() => {
				$('#deviceTypesModal').modal('hide')
				this.loadDeviceTypes()
			}).catch((e) => {
				this.$toast.error('Error happened during save' + e.response.data.Data)
			})
		},
		insert () {
			try {
				this.newDeviceType.default_license_options = JSON.parse(this.newDeviceType.default_license_options)
				if (!Array.isArray(this.newDeviceType.default_license_options)) {
					this.$toast.error('default_license_options need to be an array of objects')
					this.newDeviceType.default_license_options = JSON.stringify(this.newDeviceType.default_license_options)
					return
				}
			} catch (e) {
				this.$toast.error('Check options field ' + e)
				return
			}
			api.post('/device-type', this.newDeviceType).then(() => {
				$('#deviceTypesModalCreate').modal('hide')
				this.loadDeviceTypes()
				this.newDeviceType = {}
			}).catch((e) => {
				this.$toast.error('Error happened during save' + e.response.data.Data)
			})
		},
		remove () {
			api.delete('/device-types/' + this.selected.id).then(() => {
				$('#deviceTypesModal').modal('hide')
				this.loadDeviceTypes()
			}).catch((e) => {
				this.$toast.error('Error happened during save' + e.response.data.Data)
			})
		},
		cancel () {
			$('#deviceTypesModal').modal('hide')
			$('#deviceTypesModalCreate').modal('hide')
		},
		loadDeviceTypes () {
			api.get('/device-types-all').then((response) => {
				if (response.data.Status !== 'ok') return
				this.deviceTypes = response.data.Data
				if (!this.query) this.deviceTypesToRender = this.deviceTypes
			})
		}
	},
	mounted () {
		this.loadDeviceTypes()
	}
}
</script>
<style scoped>
.table-row:hover {
  cursor: pointer;
}
</style>
