<template>
	<div>
		<page-title title="Assign a license" />
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<select v-model="selectedLicense" class="form-select">
						<option v-for="item, i in licenses" :key="i" :value="item">{{ item.name }}</option>
					</select>
					<div v-if="selectedLicense" style="margin-top: 16px;">
						License name: <span>{{ selectedLicense.name }}</span><br />
						License key: <span>{{ selectedLicense.key }}</span><br />
						License price: <span>{{ selectedLicense.price_eu }}€ (US: {{ selectedLicense.price_us }}$)</span><br />
						License description: <span>{{ selectedLicense.description }}</span><br />
						Can be assigned by users via cloud: <span>{{ selectedLicense.is_public ? 'Yes' : 'No' }}</span><br />
						Can be assigned multiple times: <span>{{ selectedLicense.multiassign ? 'Yes' : 'No' }}</span><br />
					</div>
				</div>
				<div class="col-lg-6">
					<input v-model="search" type="text" class="form-control" placeholder="Search Device" autocomplete="off" />
					<div v-if="selectedDevice" style="margin-top: 16px;">
						Device name: <span>{{ selectedDevice.chipid }}</span><br />
						Device type: <span>{{ selectedDevice.devicetype }}</span><br />
						Device platform: <span>{{ selectedDevice.plattform }}</span><br />
						Device serial: <span>{{ selectedDevice.serial }}</span><br />
						Reference: <span>{{ selectedDevice.reference }}</span><br />
						Comment: <span>{{ selectedDevice.comment }}</span><br />
						Base License Options: <span>{{ selectedDevice.base_license_options }}</span><br />
						<div class="text-center">Already Assigned Licenses:</div>
						<table class="table mt-3">
							<thead>
								<tr>
									<td>Name</td>
									<td>Email</td>
									<td>Reference</td>
									<td>DateAssigned</td>
									<td>Key</td>
									<td>Options</td>
									<td>Actions</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="lic in selectedDevice.Licenses" :key="lic.id">
									<td>{{ lic.name }}</td>
									<td>{{ lic.email_reference }}</td>
									<td>{{ lic.invoice_reference }}</td>
									<td>{{ formatDate(lic.created_at) }}</td>
									<td>{{ lic.key }}</td>
									<td>{{ lic.options }}</td>
									<td><button class="btn btn-red" @click="deleteLicense(lic)">Delete</button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6">
					<div style="padding-top: 8px">
						Email: <input v-model="email" type="email" class="form-control" placeholder="Email reference *"
							autocomplete="off" />
					</div>
					<div style="padding-top: 8px">
						Other Email Receivers:
						<input v-model="email1" type="email" class="form-control mb-1" placeholder="Other mail" autocomplete="off" />
						<input v-model="email2" type="email" class="form-control mb-1" placeholder="Other mail" autocomplete="off" />
						<input v-model="email3" type="email" class="form-control mb-1" placeholder="Other mail" autocomplete="off" />
					</div>
					<div style="padding-top: 8px">
						Sales Order Number: <input v-model="invoice" type="text" class="form-control"
							placeholder="Sales Order Number *" autocomplete="off" />
					</div>
				</div>
				<div class="col-lg-6">
					<div style="float: right; padding-top: 26px">
						<button type="button" :disabled="disabledDownload" class="btn btn-info mx-2"
							@click="downloadDevice">Download</button>
						<button type="button" :disabled="disabled" class="btn btn-success" @click="assignLicense">Assign</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import PageTitle from '../components/PageTitle.vue'
import { api } from '@/api'
import moment from 'moment'

export default {
	name: 'AssignLicense',
	components: {
		PageTitle
	},
	data () {
		return {
			deviceTypes: {},
			selectedLicense: null,
			selectedDevice: null,
			licenses: null,
			devices: null,
			email: '',
			email1: '',
			email2: '',
			email3: '',
			invoice: '',
			search: ''
		}
	},
	watch: {
		async search (data) {
			await this.getDevices(data)
		}
	},
	computed: {
		disabled () {
			if (this.selectedDevice === undefined || this.selectedDevice === null || this.selectedLicense === null || (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) && this.invoice === '')) {
				return true
			} else {
				return false
			}
		},
		disabledDownload () {
			if (this.selectedDevice === undefined || this.selectedDevice === null) {
				return true
			} else {
				return false
			}
		}
	},
	async mounted () {
		// await this.getDevices()
		await this.getLicenses()
		if ('serial' in this.$route.query) {
			this.search = this.$route.query.serial
		}
	},
	methods: {
		formatDate (date) {
			return moment(date).format('DD-MM-YYYY HH:mm')
		},
		async assignLicense () {
			const otherMails = []
			otherMails.push(this.email1, this.email2, this.email3)
			otherMails.filter(x => x !== '')

			const body = {
				license_id: this.selectedLicense?.id,
				device_id: this.selectedDevice?.id,
				email_reference: this.email,
				invoice_reference: this.invoice,
				other_mails: otherMails
			}
			api.post('/assign-license', body).then(res => {
				this.$toast.success('Successfully assigned license')
				this.getDevices(this.search) // reload device
				this.email1 = ''
				this.email2 = ''
				this.email3 = ''
			}).catch((e) => {
				this.$toast.error('Error happened during save: ' + e.response.data.Data)
			})
		},
		async deleteLicense (lic) {
			const body = {
				license_id: lic.id,
				device_id: lic.device_id
			}
			const result = await this.$swal.fire({
				icon: 'warning',
				title: 'Really delete this license ?',
				showCancelButton: true
			})
			if (!result.isConfirmed) return

			api.post('/delete-license', body).then(res => {
				this.$toast.success('Successfully deleted license')
				this.getDevices(this.search) // reload device
			}).catch((e) => {
				this.$toast.error('Error happened during delete: ' + e.response.data.Data)
			})
		},
		async downloadDevice () {
			const body = {
				device_id: this.selectedDevice?.id
			}

			api({
				url: '/download-license',
				data: body,
				method: 'POST',
				responseType: 'blob'
			}).then((response) => {
				console.log(response)
				let filename = response.headers['content-disposition']
				filename = filename.split('filename=')[1]
				if (!filename) return
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', filename)
				document.body.appendChild(link)
				link.click()
			}).catch((err) => {
				console.log(err)
				this.$toast.error('Error happened when requesting license ', err)
			})
		},
		async getDevices (query) {
			api.get(`/search-devices?query=${query}&models=&limit=${10}&offset=${0}`).then((response) => {
				if (response.status !== 200) return
				this.selectedDevice = response.data?.[0]?.[0]
			})
		},
		async getLicenses () {
			api.get('/license-types').then((response) => {
				if (response.data.Status !== 'ok') return
				this.licenses = response.data.Data
				this.selectedLicense = this.licenses[0] ? this.licenses[0] : null
			})
		}
	}
}
</script>
