import { createRouter, createWebHistory } from 'vue-router'
import DeviceAssignment from '../views/DeviceAssignment.vue'
import AssignLicense from '../views/AssignLicense.vue'
import Artifacts from '../views/Artifacts.vue'
import Home from '../views/Home.vue'
import Signin from '../views/Signin.vue'
import SignPackage from '../views/SignPackage.vue'
import PackageManagement from '../views/PackageManagement.vue'
import SerialNumberSearch from '../views/SerialNumberSearch.vue'
import RemoteSupport from '../views/RemoteSupport.vue'
import ManageOptions from '../views/ManageOptions.vue'
import SystemUpdates from '../views/SystemUpdates.vue'
import CoreVersions from '../views/CoreVersions.vue'
import LicenseTypes from '../views/LicenseTypes.vue'
import DeviceTypes from '../views/DeviceTypes.vue'
import Sso from '../views/Sso.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/device-assignment',
    name: 'DeviceAssignment',
    component: DeviceAssignment
  },
  {
    path: '/assign-license',
    name: 'AssignLicense',
    component: AssignLicense
  },
  {
    path: '/artifacts',
    name: 'Artifacts',
    component: Artifacts
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/serial-number-search',
    name: 'SerialNumberSearch',
    component: SerialNumberSearch
  },
  {
    path: '/sign-package',
    name: 'SignPackage',
    component: SignPackage
  },
  {
    path: '/package-management',
    name: 'PackageManagement',
    component: PackageManagement
  },
  {
    path: '/remote-support',
    name: 'RemoteSupport',
    component: RemoteSupport
  },
  {
    path: '/sso',
    name: 'Sso',
    component: Sso
  },
  {
    path: '/manage-options',
    name: 'Manage Options',
    component: ManageOptions
  },
  {
    path: '/system-updates',
    name: 'System Updates',
    component: SystemUpdates
  },
  {
    path: '/core-versions',
    name: 'Core Versions',
    component: CoreVersions
  },
  {
    path: '/license-types',
    name: 'License Types',
    component: LicenseTypes
  },
  {
    path: '/device-types',
    name: 'Device Types',
    component: DeviceTypes
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = store.state.user
  if (!loggedIn && (to.path !== '/signin' && !to.path.startsWith('/sso'))) next({ name: 'Signin' })
  else next()
})

export default router
