<template>
	<div>
		<page-title title="core versions" />
		<div class="card">
			<div class="card-body">

				<div id="coreVersionModal" tabindex="-1" style="display: none;" class="modal modal-blur fade" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title">Edit Core version</h5>
								<button @click="cancel()" type="button" class="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
							</div>
							<div class="modal-body" v-if="selectedCoreVersion">
								<div class="mb-1">
									<label class="form-label">Core Name</label>
									<input class="form-control" disabled :value="selectedCoreVersion.corename" />
								</div>
								<div class="mb-1">
									<label class="form-label">Version</label>
									<input class="form-control" disabled :value="selectedCoreVersion.version_id" />
								</div>
								<div>
									<label class="form-label">Changelog</label>
									<textarea style="min-height: 200px;" class="form-control"
										v-model="selectedCoreVersion.changelog"></textarea>
								</div>
								<div>
									<label class="form-label">Published</label>
									<input id="publishBox" type="checkbox" :checked="selectedCoreVersion.changelog_published !== null"
										@change="pubLog($event)" />
								</div>
							</div>
							<div class="modal-footer">
								<button type="button" class="btn" @click="cancel()">Close</button>
								<button type="button" class="btn btn-primary" @click="update()">Update</button>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-8">
						<input v-model="core" placeholder="Search device type" class="form-control query" type="text" />
					</div>
					<div class="col-sm-1 d-flex align-items-center">
						<font-awesome-icon icon="spinner" class="fa-spin" v-if="cancelRequest.token" />
					</div>
					<div class="d-flex col-sm-3 align-items-center justify-content-end">
						<button v-if="filtersDirty" @click="resetFilters" class="btn btn-default mr-1" title="Reset filters">
							<font-awesome-icon :icon="['fas', 'trash']" />
						</button>
						<button class="btn btn-default" type="button" data-toggle="collapse" data-target="#collapse" aria-expanded="false" aria-controls="collapse">
							<font-awesome-icon :icon="['fas', 'filter']" />
						</button>
					</div>
				</div>
				<div class="row collapse mt-1" id="collapse">
					<div class="col-md-3">
						<label>Installs amount range:</label>
						<div class="row">
							<div class="col-md-6">
								<input v-model="installsAmountFrom" class="form-control" type="number" placeholder="From">
							</div>
							<div class="col-md-6">
								<input v-model="installsAmountTo" class="form-control" type="number" placeholder="To">
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<label>Installs date range:</label>
						<div class="row">
							<div class="col-md-6">
								<input v-model="dateFrom" class="form-control" type="date" min="2023-10-26" placeholder="from">
							</div>
							<div class="col-md-6">
								<input v-model="dateTo" class="form-control" type="date" placeholder="to">
							</div>
						</div>
					</div>
					<div class="col-md-2">
						<label>Package published:</label>
						<div class="row">
							<div class="col-md-12">
								<select class="form-control" v-model="published">
									<option :value="undefined" :selected="published === undefined">All</option>
									<option :value="true" :selected="published === true">Yes</option>
									<option :value="false" :selected="published === false">No</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col-md-2">
						<label>Changelog published:</label>
						<div class="row">
							<div class="col-md-12">
								<select class="form-control" v-model="changelogPublished">
									<option :value="undefined" :selected="changelogPublished === undefined">All</option>
									<option :value="true" :selected="changelogPublished === true">Yes</option>
									<option :value="false" :selected="changelogPublished === false">No</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col-md-2">
						<label>Version:</label>
						<div class="row">
							<div class="col-md-12">
								<select class="form-control" v-model="version">
									<option :value="undefined" :selected="version === undefined">All</option>
									<option value="stable" :selected="version === 'stable'">Stable</option>
									<option value="pre" :selected="version === 'pre'">Pre</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<table class="table table-xl mt-3">
					<thead>
						<tr>
							<th>Name</th>
							<th>Version</th>
							<th>Published</th>
							<th>ChangelogPublished</th>
							<th>Installs (Since 26/10/23)</th>
							<th>Offline</th>
							<th>Online</th>
						</tr>
					</thead>
					<tbody v-for="core of coreVersions" :key="core">
						<tr
							class="table-row"
							@click="edit(version)"
							v-for="version of core.versions"
							:key="version"
							onmouseover="this.classList.add('table-info')"
							onmouseout="this.classList.remove('table-info')"
						>
							<td>{{ version.corename }}</td>
							<td>{{ version.version_id }}</td>
							<td>{{ version.published ? formatDate(version.published) : 'No' }}</td>
							<td>{{ version.changelog_published ? formatDate(version.changelog_published) : 'No' }}</td>
							<td>{{ version.installs }}</td>
							<td>{{ version.offline_installs }}</td>
							<td>{{ version.online_installs }}</td>
						</tr>
						<tr class="table-info">
							<td>Total:</td>
							<td>{{ core.versions.length }}</td>
							<td></td>
							<td></td>
							<td>{{ core.installs }}</td>
							<td>{{ core.offline_installs }}</td>
							<td>{{ core.online_installs }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import moment from 'moment'
import $ from 'jquery'
import { debounce, isEqual, isEmpty, omitBy } from 'lodash'
import axios from 'axios'

import semver from '@/utils/semver.js'
import PageTitle from '@/components/PageTitle.vue'
import { getCoreVersions, updateCoreVersion } from '@/api/core-versions'

const filters = ['core', 'installsAmountFrom', 'installsAmountTo', 'dateFrom', 'dateTo', 'published', 'changelogPublished', 'version']

const computedFilters = {}
for (const f of filters) {
	computedFilters[f] = {}
	computedFilters[f].get = function () {
		return this.$route.query[f]
	}
	computedFilters[f].set = function (value) {
		this.$router.replace({ query: { ...this.$route.query, [f]: value } })
	}
}

const { CancelToken } = axios

export default {
	data () {
		return {
			coreVersions: {},
			coreVersionsToRender: null,
			selectedCoreVersion: null,
			cancelRequest: {},
			publishChangelog: ''
		}
	},
	components: {
		PageTitle
	},
	watch: {
		'$route.query': {
			handler (newFilters) {
				this.debounceFetchCoreVersions(newFilters)
			},
			deep: true
		}
	},
	computed: {
		filtersDirty () {
			return !isEqual(omitBy(this.$route.query, isEmpty), {})
		},
		...computedFilters
	},
	methods: {
		resetFilters () {
			this.$router.replace({ query: {} })
		},
		formatDate (date) {
			return moment(date).format('DD-MM-YYYY HH:mm')
		},
		edit (version) {
			this.publishChangelog = ''
			this.selectedCoreVersion = version

			$('#coreVersionModal').modal('show')
		},
		pubLog (e) {
			if (e.target.checked) {
				this.publishChangelog = 'now'
			} else {
				this.publishChangelog = 'clear'
			}
		},
		update () {
			const dataToSend = {
				CoreName: this.selectedCoreVersion.corename,
				CoreVersion: this.selectedCoreVersion.version_id,
				Changelog: this.selectedCoreVersion.changelog,
				ChangelogPublish: this.publishChangelog
			}
			updateCoreVersion(dataToSend).then(() => {
				$('#coreVersionModal').modal('hide')
				this.fetchCoreVersions(this.$route.query)
				this.publishChangelog = ''
			}).catch(() => {
				this.$toast.error('Error happened during save')
			}).finally(() => {
				this.selectedCoreVersion = null
			})
		},
		cancel () {
			$('#coreVersionModal').modal('hide')
			this.selectedCoreVersion = null
		},
		debounceFetchCoreVersions: debounce(function (...args) { this.fetchCoreVersions(...args) }, 400),
		fetchCoreVersions (filters) {
			if (this.cancelRequest.token) this.cancelRequest.cancel()
			this.cancelRequest = CancelToken.source()
			getCoreVersions(filters, this.cancelRequest.token).then(({ data: coreVersions }) => {
				this.cancelRequest = {}
				if (coreVersions.Status !== 'ok') return
				const out = {}
				for (const coreVersion of coreVersions.Data || []) {
					if (!out[coreVersion.corename]) {
						out[coreVersion.corename] = {
							versions: [coreVersion],
							installs: coreVersion.installs,
							online_installs: coreVersion.online_installs,
							offline_installs: coreVersion.offline_installs
						}
					} else {
						out[coreVersion.corename].versions.push(coreVersion)
						out[coreVersion.corename].installs += coreVersion.installs
						out[coreVersion.corename].online_installs += coreVersion.online_installs
						out[coreVersion.corename].offline_installs += coreVersion.offline_installs
					}
				}
				for (const core of Object.keys(out)) {
					out[core].versions = out[core]?.versions?.sort((a, b) => semver.rcompare(a.version_id, b.version_id))
				}
				this.coreVersions = out
			}).catch(error => {
					this.cancelRequest = {}
					if (axios.isCancel(error)) {
						console.log('request canceld', error.message)
					}
				})
		}
	},
	beforeUnmount () {
		this.cancelRequest.cancel?.()
	}
}
</script>
<style lang="scss" scoped>
.table-row:hover {
	cursor: pointer;
}
.mr-1 {
	margin-right: 0.25rem;
}
</style>
