<template>
	<page-title title="Package management" />
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<pending-packages :refetchTrigger="refetchTrigger" :handleRefetch="handleRefetch" :searchData="searchData"
					@setSearch="setSearch" />
			</div>
			<div class="col-md-6">
				<published-packages :refetchTrigger="refetchTrigger" :handleRefetch="handleRefetch" :searchData="searchData"
					@setSearch="setSearch" />
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'
import PendingPackages from '../components/PendingPackages.vue'
import PublishedPackages from '../components/PublishedPackages.vue'

export default {
  name: 'PackageManagement',
  components: { PageTitle, PendingPackages, PublishedPackages },
  data () {
    return { refetchTrigger: false, searchData: '' }
  },
  methods: {
    handleRefetch () {
      this.refetchTrigger = !this.refetchTrigger
    },
    setSearch (data) {
      this.searchData = data
    }
  }
}
</script>
