import _ from 'lodash'
import { api } from '.'

export function getDevices () {
  return api.get('/devices')
}

export function identifyDevice (deviceid) {
  return api.post('/identify', { deviceid })
}

export function licenseDevice (
  deviceid,
  devicetype_id,
  deviceserial,
  deviceoptions
) {
  return api.post('/license', {
    deviceid,
    devicetype_id,
    deviceserial,
    deviceoptions
  })
}

export function searchDevices (query, page, filterModels) {
  const formattedQuery = _.compact(query.split(/,\s*/)).join(',')
  const formattedModels = _.compact(filterModels.split(/,\s*/)).join(',')
  const limit = 10
  const offset = (page - 1) * limit
  return api.get('/search-devices?query=' + formattedQuery + `&models=${formattedModels}&limit=${limit}&offset=${offset}`)
}

export function deleteDevice (id) {
  return api.delete(`/devices/${id}`)
}

export function updateDevice (id, body) {
  return api.put(`/devices/${id}`, body)
}

export function resetBurnin (serial) {
  return api.get('/reset-burnin?serial=' + serial)
}
