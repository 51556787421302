<template>
	<div>
		<page-title title="Artifacts" />
		<div class="card">
			<div class="card-body">
				<table class="table table-xl mt-3">
          <thead>
            <tr>
              <th>Name</th>
              <th>Created At</th><th>Option</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="table-row"
              v-for="(item, i) in artifacts"
              :key="i"
            >
              <td>{{ item.Name }}</td>
              <td>{{ item.Created }}</td>
              <td>
				<button type="button" class="btn btn-info mx-2" @click="downloadArtifact(item.Name)">Download</button>
			</td>
            </tr>
          </tbody>
        </table>
			</div>
		</div>
	</div>
</template>
<script>
import PageTitle from '../components/PageTitle.vue'
import { api } from '@/api'
export default {
  name: 'Artifacts',
  components: {
    PageTitle
  },
  data () {
    return {
      artifacts: null
    }
  },
  mounted () {
    this.getArtifacts()
  },
  methods: {
    async getArtifacts () {
      api.get('/artifacts').then((response) => {
        if (response.data.Status !== 'ok') return
        this.artifacts = response.data.Data.Artifacts
        this.artifacts.sort(function (a, b) {
          return new Date(b.Created) - new Date(a.Created)
        })
      })
    },
    async downloadArtifact (name) {
      window.open(`/api/artifact/download/${name}`)
    }
  }
}
</script>
