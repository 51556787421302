<template>
	<div class="card card-body">
		<div class="d-flex align-items-center">
			<div class="subheader">Users Registered</div>
			<div class="ms-auto lh-1">
				<div class="dropdown" v-if="!loading">
					<a class="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Last {{daysSelected}} days</a>
					<div class="dropdown-menu dropdown-menu-end">
						<a class="dropdown-item" :class="daysSelected == 7 ? 'active' : ''" @click="selectDays(7)">Last 7 days</a>
						<a class="dropdown-item" :class="daysSelected == 30 ? 'active' : ''" @click="selectDays(30)">Last 30 days</a>
						<a class="dropdown-item" :class="daysSelected == 90 ? 'active' : ''" @click="selectDays(90)">Last 90 days</a>
					</div>
				</div>
				<font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
			</div>
		</div>
		<div class="d-flex align-items-baseline">
			<div class="h1 mb-3 me-2">{{total}}</div>
		</div>
		<apexchart
			height="40"
			type="line"
			ref="chart"
			v-bind="options"
			:options="options"
			:series="series"
		></apexchart>
	</div>
</template>

<script>
import moment from 'moment'
import { api } from '@/api'
export default {
	name: 'UsersRegisteredChart',
	mounted () {
		this.loadData()
	},
	methods: {
		selectDays (days) {
			this.daysSelected = days
			this.loadData()
		},
		loadData () {
			this.loading = true
			api.get('/stat/users-registered/' + this.daysSelected).then(response => {
				this.loading = false
				if (response.data.Status === 'ok') {
					const out = []
					this.total = 0
					for (const entry of response.data.Data) {
						this.total += entry.count
						out.push([Math.floor(+new Date(entry.date) / 1000), entry.count])
					}
					this.series[0].data = out
					this.$refs.chart.updateSeries([{
						data: this.series[0].data
					}], false, true)
				}
			}).catch(error => {
					this.loading = false
					console.error(error)
				})
		}
	},
	data () {
		return {
			total: 0,
			daysSelected: 30,
			loading: false,
			options: {
				type: 'area',
				legend: {
					show: false
				},
				grid: {
					show: false
				},
				xaxis: {
					labels: {
						show: false
					},
					axisTicks: {
						show: false
					},
					crosshairs: {
						show: false
					}
				},
				yaxis: {
					labels: {
						show: false
					},
					axisTicks: {
						show: false
					},
					crosshairs: {
						show: false
					}
				},
				stroke: {
					width: [2, 2],
					curve: 'smooth',
					dashArray: [0, 4]
				},
				tooltip: {
					x: {
						format: 'dd MMM yyyy',
						formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
							return moment(value * 1000).format('Do MMM')
						}
					}
				},
				chart: {
					datetime: true,
					sparkline: {
						enabled: true
					},
					'stroke-width': [2, 1],
					'stroke-dash': [0, 3],
					toolbar: {
						show: false
					}
				},
				theme: { mode: this.$store.state.theme }
			},
			series: [
				{
					name: 'Users',
					color: '#206bc4',
					data: []
				}
			]

		}
	},
	computed: {
		theme () { return this.$store.state.theme }
	},
	watch: {
		theme (val) {
			this.$refs.chart.updateOptions({ theme: { mode: val } })
		}
	}
}
</script>
