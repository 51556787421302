<template>
	<div class="card">

	<div class="card-header">
		<div class="col-md-4 p-2">
			<button class="btn w-100 btn-primary" @click="openUploadDialog">
				Upload new package
			</button>
		</div>

		<div class="col-md-4 p-2">
			<button class="btn w-100 btn-danger" @click="deleteSelected" :disabled="!selected.length">
				Delete
			</button>
		</div>

		<div class="col-md-4 p-2">
			<button class="btn w-100 btn-success" @click="publishSelected">
				Publish
			</button>
		</div>
	</div>

	<div class="card-body">
		<input placeholder="Query..." class="form-control" type="text" v-model="search" />

		<div class="progress" v-if="uploadProgress > 0">
			<div
				:style="`width: ${uploadProgress}%;`"
				:aria-valuenow="uploadProgress"
				class="progress-bar bg-success"
				role="progressbar"
				aria-valuemin="0"
				aria-valuemax="100"
			>
				{{uploadProgress}}%
			</div>
		</div>

		<table class="table table-xl mt-3">
			<thead>
				<tr>
					<th>
						<input type="checkbox" class="form-check-input mt-0" @change="toggleSelectAll">
					</th>
					<th>Name</th>
					<th>Version</th>
					<th>md5sum</th>
					<th>Added</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="Package in packagesShow" v-bind:key="Package.id">
					<td>
						<input
							type="checkbox"
							class="form-check-input mt-0"
							:checked="selected.includes(Package.id)"
							@change="toggleSelected(Package)"
						/>
					</td>
					<td>{{Package.name}}</td>
					<td>{{Package.version}}</td>
					<td @click="copyToClipboard(Package.md5sum)" style="cursor: pointer">
						{{Package.md5sum.slice(Package.md5sum.length - 6)}}
					</td>
					<td>{{Package.status === PACKAGE_STATUSES.PENDING_REMOVE ? 'Pending' : formatDate(Package.created_at)}}</td>
				</tr>
			</tbody>
		</table>
		<ul class="pagination" style="float:right" v-if="packages.length > 0">
      <li class="page-item" :class="currentPage !== 1 ? '' : 'disabled'">
        <a @click="prevPage" class="page-link" href="#" tabindex="-1" aria-disabled="true">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg>
        </a>
      </li>
      <li v-for="page of Math.ceil(packages.length / itemsPerPage)" :key="page" :class="page === currentPage ? 'active' : ''" class="page-item"><a @click="switchPage(page)" class="page-link" href="#">{{page}}</a></li>
      <li class="page-item">
        <a class="page-link" href="#" @click="nextPage">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>
        </a>
      </li>
      <li class="page-item">
				<input v-model="itemsPerPage" type="number" style="height: 28px; width: 60px;" class="form-control">
      </li>
    </ul>
	</div>

	</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import {
  deletePackages,
  getPackages,
  updatePackagesStatus,
  uploadPackage
} from '@/api/packages'
import PACKAGE_STATUSES from '@/utils/package-statuses'

export default {
  name: 'PendingPackages',
  props: {
    handleRefetch: Function,
    refetchTrigger: Boolean,
    searchData: String
  },
  data () {
    return {
      packages: [],
      packagesShow: [],
      selected: [],
      uploadProgress: 0,
      filesToUpload: [],
      currentPage: 1,
      itemsPerPage: 50
    }
  },
  computed: {
    search: {
      get () {
        return this.searchData
      },
      set (data) {
        this.$emit('setSearch', data)
      }
    }
  },
  watch: {
    search (newValue) {
      this.packages = []
      this.searchPackages(newValue)
      this.switchPage(1)
    },
    refetchTrigger () {
      this.searchPackages(this.search)
      this.switchPage(1)
    },
    packages () {
      this.switchPage(1)
    },
    itemsPerPage (data) {
      if (!data) this.itemsPerPage = 1
      this.switchPage(1)
    }
  },
  mounted () {
    this.searchPackages()
    this.PACKAGE_STATUSES = PACKAGE_STATUSES
    this.switchPage(1)
  },
  methods: {
    switchPage (page = this.currentPage) {
      this.currentPage = page
      this.packagesShow = this.packages.slice((page - 1) * this.itemsPerPage, page * this.itemsPerPage)
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.switchPage()
      }
    },
    nextPage () {
      if (this.currentPage < Math.ceil(this.packages.length / this.itemsPerPage)) {
        this.currentPage++
        this.switchPage()
      }
    },
    toggleSelected (Package) {
      const alreadySelected = this.selected.includes(Package.id)

      if (alreadySelected) {
        this.selected = this.selected.filter(s => s !== Package.id)
      } else {
        this.selected = [...this.selected, Package.id]
      }
    },
    toggleSelectAll (event) {
      if (event.target.checked) {
        this.selected = this.packages.map(p => p.id)
      } else {
        this.selected = []
      }
    },
    publishSelected () {
      updatePackagesStatus(this.selected, PACKAGE_STATUSES.PENDING_PUBLISH)
        .then(this.handleRefetch)
    },
    async deleteSelected () {
      const someOfPackagesArePending = this.selected.some(id => {
        const Package = this.packages.find(p => p.id === id)
        return Package.status === PACKAGE_STATUSES.PENDING_REMOVE
      })
      if (someOfPackagesArePending) {
        this.$swal.fire({
          icon: 'error',
          title: 'Error deleting packages',
          text: 'Some of packages you\'re trying to delete are pending. You have to publish index before removing these.'
        })
        return
      }

      const result = await this.$swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        showCancelButton: true
      })
      if (!result.isConfirmed) return

      deletePackages(this.selected).then(() => {
        this.$swal.fire(
          'Deleted!',
          'Packages has been deleted.',
          'success'
        )
        this.handleRefetch()
      })
    },
    async openUploadDialog () {
      const { value: files } = await this.$swal.fire({
        title: 'Select file',
        input: 'file',
        inputAttributes: { multiple: true },
        showCancelButton: true,
        confirmButtonText: 'Upload'
      })
      this.filesToUpload = files
      await this.startUploading()
    },
    async startUploading () {
      const files = this.filesToUpload
      if (!files || !files.length) return

      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        await uploadPackage(file, progressEvent => {
          const progress = progressEvent.loaded / progressEvent.total
          this.uploadProgress = Math.ceil((i + progress) / files.length * 100)
        })
      }
      this.filesToUpload = []
      await this.$swal.fire(
        'Upload completed!',
				`${files.length} ${files.length === 1 ? 'package' : 'packages'} has been uploaded`,
				'success'
      )
      this.uploadProgress = 0
      this.handleRefetch()
    },
    searchPackages: _.debounce(function (query) {
      getPackages(
        query,
        [
          PACKAGE_STATUSES.NOT_PUBLISHED,
          PACKAGE_STATUSES.PENDING_REMOVE
        ]
      ).then(response => {
        this.selected = []
        if (!Array.isArray(response.data.Data)) {
          this.packages = []
          return
        }

        this.packages = response.data.Data
      })
    }, 400),
    formatDate (date) {
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    async copyToClipboard (md5) {
      await navigator.clipboard.writeText(md5)
      this.$toast.success(
        'md5sum copied to clipboard',
        { position: 'bottom-right' }
      )
    }
  }
}
</script>

<style scoped>
.progress {
	margin: 12px 0 0 0;
	height: 12px;
}
</style>
