// ########################################################################
// ##  IMPORTANT: node_modules / semver should be "5.7.1" version        ##
// ##  Here I'm ovveriding comparePre to use semverCompareIdentifiers    ##
// ##  function instead of original because we use semver                ##
// ##  a bit differently: (                                              ##
// ########################################################################

import semver, { SemVer } from 'semver'

function semverCompareIdentifiers (a, b) {
	const numeric = /^[0-9]+$/
	const anum = numeric.test(a)
	const bnum = numeric.test(b)

	if (anum && bnum) {
		a = +a
		b = +b
	}
	if (a === b) {
		return 0
	} else if (anum && !bnum) {
		return -1
	} else if (bnum && !anum) {
		return 1
	} else if (isNaN(a) && isNaN(b)) {
		if (parseInt(a.slice(3)) < parseInt(b.slice(3))) {
			return -1
		} else {
			return 1
		}
	} else if (a < b) {
		return -1
	} else {
		return 1
	}
}

SemVer.prototype.comparePre = function (other) {
	if (!(other instanceof SemVer)) {
		other = new SemVer(other, this.options)
	}

	// NOT having a prerelease is > having one
	if (this.prerelease.length && !other.prerelease.length) {
		return -1
	} else if (!this.prerelease.length && other.prerelease.length) {
		return 1
	} else if (!this.prerelease.length && !other.prerelease.length) {
		return 0
	}

	let i = 0

	do {
		const a = this.prerelease[i]
		const b = other.prerelease[i]
		if (a === undefined && b === undefined) {
			return 0
		} else if (b === undefined) {
			return 1
		} else if (a === undefined) {
			return -1
		} else if (a === b) {
			continue
		} else {
			return semverCompareIdentifiers(a, b)
		}
	} while (++i)
}
export default semver
