<template>
	<div class="card card-body">
		<div class="d-flex align-items-center">
			<div class="subheader">Top 10 cores installed</div>
			<div class="ms-auto lh-1">
				<div class="dropdown" v-if="!loading">
					<a class="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						{{CardTitle}}
					</a>
					<div class="dropdown-menu dropdown-menu-end">
						<a class="dropdown-item" :class="daysSelected == 7 ? 'active' : ''" @click="selectDays(7)">Last 7 days</a>
						<a class="dropdown-item" :class="daysSelected == 30 ? 'active' : ''" @click="selectDays(30)">Last 30 days</a>
						<a class="dropdown-item" :class="daysSelected == 90 ? 'active' : ''" @click="selectDays(90)">Last 90 days</a>
						<a class="dropdown-item" :class="{ 'active': daysSelected == 'all-time' }" @click="selectDays('all-time')">All time</a>
					</div>
				</div>
				<font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
			</div>
		</div>
		<div class="row align-items-center">
			<div class="col">
				<div v-for="core, i of Cores" :key="i" :title="getTitle(core)">
					<span class="font-weight-medium">
						{{core.corename}} - {{core.total}}
					</span>
				</div>
			</div>
			<div class="col-auto"></div>
		</div>
	</div>
</template>

<script>
import { groupBy, sum, sumBy } from 'lodash'

import { api } from '@/api'

export default {
	name: 'CoresInstalled',
	data () {
		return {
			daysSelected: 30,
			loading: false,
			coresData: {}
		}
	},
	computed: {
		CardTitle () {
			if (this.daysSelected === 'all-time') return 'All time'
			return `Last ${this.daysSelected} days`
		},
		Cores () {
			const grouped = groupBy(this.coresData, c => c.corename)
			const arr = []

			for (const corename in grouped) {
				const item = grouped[corename]
				arr.push({
					corename,
					online: item.find(i => i.source === 'pkg.skaarhoj.com')?.count,
					offline: item.find(i => i.source === 'devices.skaarhoj.com')?.count,
					total: sumBy(item, i => i.count)
				})
			}

			return arr.sort((a, b) => b.total - a.total).slice(0, 10)
		}
	},
	methods: {
		getTitle (core) {
			const res = ['Installed:']

			res.push(`Online - ${core.online || 0}`)
			res.push(`Offline - ${core.offline || 0}`)

			return res.join('\n')
		},
		getSum (cores) {
			return sum(cores.map(c => c.count))
		},
		selectDays (days) {
			this.daysSelected = days
			this.loadData()
		},
		loadData () {
			this.loading = true
			api.get('/stat/cores-installed/' + this.daysSelected).then(response => {
				this.loading = false
				if (response.data.Status === 'ok') {
					this.coresData = response.data.Data
				}
			}).catch(e => console.error(e.response))
		}
	},
	mounted () {
		this.loadData()
	}
}
</script>
