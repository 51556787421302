<template>
	<div class="card">

		<div class="card-header">
			<div class="col-md-4 p-2">
				<button class="btn w-100 btn-danger" @click="unpublishSelected">
					Unpublish
				</button>
			</div>

			<div class="col-md-4 p-2">
				<button class="btn w-100 btn-warning" @click="handlePublishIndex">
					Publish Index
				</button>
			</div>
			<div class="col-md-4 p-2">
				<span>Status: {{indexStatus}}
					<font-awesome-icon style="cursor: pointer;" @click="fetchIndexHealth" icon="sync" />
				</span>
			</div>
		</div>

		<div class="card-body">
			<input placeholder="Query..." class="form-control" type="text" v-model="search" />
			<table class="table table-xl mt-3">
				<thead>
					<tr>
						<th>
							<input type="checkbox" class="form-check-input mt-0" @change="toggleSelectAll">
						</th>
						<th>Name</th>
						<th>Version</th>
						<th>Published</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="Package in packagesShow" v-bind:key="Package.id">
						<td>
							<input :checked="selected.includes(Package.id)" @change="toggleSelected(Package)" type="checkbox"
								class="form-check-input mt-0" />
						</td>
						<td>{{Package.name}}</td>
						<td>{{Package.version}}</td>
						<td>{{Package.status === PACKAGE_STATUSES.PUBLISHED ? formatDate(Package.published_at) : 'Pending'}}</td>
					</tr>
				</tbody>
			</table>
			<ul class="pagination" style="float:right" v-if="packages.length > 0">
				<li class="page-item" :class="currentPage !== 1 ? '' : 'disabled'">
					<a @click="prevPage" class="page-link" href="#" tabindex="-1" aria-disabled="true">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
							stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="15 6 9 12 15 18"></polyline>
						</svg>
					</a>
				</li>
				<li v-for="page of Math.ceil(packages.length / itemsPerPage)" :key="page"
					:class="page === currentPage ? 'active' : ''" class="page-item"><a @click="switchPage(page)" class="page-link"
						href="#">{{page}}</a></li>
				<li class="page-item">
					<a class="page-link" href="#" @click="nextPage">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
							stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="9 6 15 12 9 18"></polyline>
						</svg>
					</a>
				</li>
				<li class="page-item">
					<input v-model="itemsPerPage" type="number" style="height: 28px; width: 60px;" class="form-control">
				</li>
			</ul>
		</div>

	</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { getPackages, publishIndex, updatePackagesStatus, uploadPackage, getIndexHealth } from '@/api/packages'
import PACKAGE_STATUSES from '@/utils/package-statuses'

export default {
  name: 'PublishedPackages',
  props: {
    handleRefetch: Function,
    refetchTrigger: Boolean,
    searchData: String
  },
  data () {
    return {
      packages: [],
      selected: [],
      indexStatus: '',
      currentPage: 1,
      itemsPerPage: 50,
      packagesShow: []
    }
  },
  computed: {
    search: {
      get () {
        return this.searchData
      },
      set (data) {
        this.$emit('setSearch', data)
      }
    }
  },
  watch: {
    search (newValue) {
      this.packages = []
      this.searchPackages(newValue)
      this.switchPage(1)
    },
    refetchTrigger () {
      this.searchPackages(this.search)
      this.switchPage(1)
    },
    packages () {
      this.switchPage(1)
    },
    itemsPerPage (data) {
      if (!data) this.itemsPerPage = 1
      this.switchPage(1)
    }
  },
  mounted () {
    this.PACKAGE_STATUSES = PACKAGE_STATUSES
    this.searchPackages()
    this.fetchIndexHealth()
    this.switchPage(1)
  },
  methods: {
    switchPage (page = this.currentPage) {
      this.currentPage = page
      this.packagesShow = this.packages.slice((page - 1) * this.itemsPerPage, page * this.itemsPerPage)
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.switchPage()
      }
    },
    nextPage () {
      if (this.currentPage < Math.ceil(this.packages.length / this.itemsPerPage)) {
        this.currentPage++
        this.switchPage()
      }
    },
    fetchIndexHealth () {
      this.indexStatus = 'Fetching...'
      getIndexHealth().then(response => {
        this.indexStatus = response.data
      }).catch(() => {
        this.indexStatus = 'Error'
      })
    },
    toggleSelected (Package) {
      const alredySelected = this.selected.includes(Package.id)

      if (alredySelected) {
        this.selected = this.selected.filter(s => s !== Package.id)
      } else {
        this.selected = [...this.selected, Package.id]
      }
    },
    toggleSelectAll (event) {
      if (event.target.checked) {
        this.selected = this.packages.map(p => p.id)
      } else {
        this.selected = []
      }
    },
    unpublishSelected () {
      updatePackagesStatus(this.selected, PACKAGE_STATUSES.PENDING_REMOVE)
        .then(this.handleRefetch)
    },
    async openUploadDialog () {
      const { value: file } = await this.$swal.fire({
        title: 'Select file',
        input: 'file',
        showCancelButton: true
      })

      if (!file) return

      uploadPackage(file).then(this.searchPackages)
    },
    searchPackages: _.debounce(function (query) {
      getPackages(
        query,
        [
          PACKAGE_STATUSES.PENDING_PUBLISH,
          PACKAGE_STATUSES.PUBLISHED
        ]
      ).then(response => {
        this.selected = []
        if (!Array.isArray(response.data.Data)) {
          this.packages = []
          return
        }

        this.packages = response.data.Data
      })
    }, 400),
    formatDate (date) {
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    handlePublishIndex () {
      this.$swal.fire({
        title: 'Publishing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })
      publishIndex().then((response) => {
        if (response.data.Status === 'ok') {
          this.$swal.close('')
          this.handleRefetch()
          this.fetchIndexHealth()
        }
      }).catch((err) => {
        console.log('Publish index error', err)
        this.$swal.close('')
        this.$swal.fire({
          title: 'Publishing Error',
          html: err,
          icon: 'error'
        })
        this.fetchIndexHealth()
      })
    }
  }
}
</script>
