<template>
	<div>
		<page-title title="system updates" />
		<div class="row">
			<div class="col-xs-6 col-md-6 col-lg-4">
				<SystemUpdatesUpload />
			</div>
		</div>
	</div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'
import SystemUpdatesUpload from '@/components/SystemUpdatesUpload.vue'
export default {
	components: {
		PageTitle,
		SystemUpdatesUpload
	}
}
</script>
