import { api, API_URL, API_PREFIX } from '.'

export function getCoreVersions (filters = {}, cancelToken) {
	const url = new URL(API_PREFIX + '/core-versions', API_URL)
	for (const f in filters) {
		if (filters[f] === undefined) continue
		url.searchParams.append(f, filters[f])
	}
	return api.get(url, { cancelToken })
}

export function updateCoreVersion (data) {
	return api.post('/core-version', data)
}
