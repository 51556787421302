<template>
<div>
	<div class="card">
		<div class="card-header">
			<h3 class="card-title">Upload file</h3>
		</div>
		<div class="card-body">
			<input type="file" multiple ref="file" />
			<div id="progressDiv">
			</div>
		</div>
		<div class="card-footer">
			<button class="btn btn-success" style="float:right" @click="submitFile()">Upload file</button>
		</div>
	</div>
</div>
</template>

<script>
import {
  uploadFile
} from '../api/system-updates'
export default {
  name: 'SystemUpdatesUpload',
  components: {},
  methods: {
    submitFile () {
      const files = this.$refs?.file?.files
      if (files?.length > 0) {
        const progressDiv = document.getElementById('progressDiv')
        const initialIndex = 0
        for (const file of files) {
          const index = initialIndex + 1
          const formData = new FormData()
          progressDiv.innerHTML +=
					`
						<div id="progress${index}">
							<span>${file.name} </span>
							<div class="progress mt-3">
								<div class="progress-bar progress-bar-indeterminate bg-green"></div>
							</div>
						</div>
					`
          formData.append('file', file)
          uploadFile(formData).then((response) => {
            if (response.data.Status === 'ok') {
              this.$toast.success('File uploaded successfully: ' + file.name)
              const progressBar = document.getElementById(`progress${index}`)
              progressBar.parentNode.removeChild(progressBar)
            } else {
              this.$toast.error(response.data.Data)
            }
          }).catch((err) => {
            const progressBar = document.getElementById(`progress${index}`)
            progressBar.parentNode.removeChild(progressBar)
            this.$toast.error(err)
          })
        }
      } else {
        this.$toast.error('Please upload file first!')
      }
    }
  }
}
</script>
<style>
.card-title {
	font-weight: bold !important;
}

.hidden {
	display: none !important;
}
</style>
