<template>
	<div class="card card-sm">
		<div class="card-body">
			<div class="row align-items-center">
				<div class="col-auto">
					<span class="bg-primary text-white avatar">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-gamepad" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<rect x="2" y="6" width="20" height="12" rx="2"></rect>
							<path d="M6 12h4m-2 -2v4"></path>
							<line x1="15" y1="11" x2="15" y2="11.01"></line>
							<line x1="18" y1="13" x2="18" y2="13.01"></line>
						</svg>
					</span>
				</div>
				<div class="col">
					<div class="font-weight-medium">{{count}}</div>
					<div class="text-muted">Devices Registered</div>
				</div>
				<div class="col-auto">
					<font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import { api } from '@/api'

const loading = ref(false)
const count = ref(0)

function fetchData () {
	loading.value = true
	api.get('/stat/devices-registered')
		.then(response => {
			loading.value = false
			if (response.data.Status === 'ok') {
				count.value = response.data.Data
			}
		})
		.catch(error => {
			loading.value = false
			console.error(error)
		})
}

onMounted(() => {
	fetchData()
})
</script>
